import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

// Twitter: https://developer.twitter.com/en/docs/media/upload-media/uploading-media/media-best-practices.html
// TikTok: https://bytedance.feishu.cn/docs/doccnq3SeHrZkncd0AdxL69MCEe#DMh0RO
// Facebook Reels: https://developers.facebook.com/docs/video-api/guides/reels-publishing/
const dimRules = {
  facebookReel: {
    minWidth: 540,
    minHeight: 960
  },
  linkedin: {
    minWidth: 144,
    maxWidth: 4096,
    minHeight: 256,
    maxHeight: 2304
  },
  tiktok: {
    minHeight: 360,
    minWidth: 360
  },
  snapchat: {
    minWidth: 540,
    minHeight: 960
  }
};

@classic
class VideoDimensions extends BaseValidator {
  validate(value, _, model) {
    if (model.isVideo) {
      let profileType = model.get('gram.socialProfile.profileType');
      profileType += model.isFacebookReel ? 'Reel' : '';
      const height = model.croppedHeight || model.get('mediaItem.height');
      const width = model.croppedWidth || model.get('mediaItem.width');
      const validationRules = dimRules[profileType];
      if (validationRules) {
        const subceedMinDimensions = width < validationRules.minWidth || height < validationRules.minHeight;
        const exceedMaxDimensions = width > validationRules.maxWidth || height > validationRules.maxHeight;
        if (subceedMinDimensions || exceedMaxDimensions) {
          if (model.isFacebookReel || (model.isTiktok && model.isAutoPublish) || model.isSnapchat) {
            return this.createErrorMessage('invalidVideoDimensionsMinimum', null, {
              minWidth: validationRules.minWidth,
              minHeight: validationRules.minHeight
            });
          } else if (model.isLinkedin) {
            return this.createErrorMessage('invalidVideoDimensions', null, {
              minWidth: validationRules.minWidth,
              minHeight: validationRules.minHeight,
              maxWidth: validationRules.maxWidth,
              maxHeight: validationRules.maxHeight
            });
          }
        }
      }
    }
    return true;
  }
}

export default VideoDimensions;
