import Service, { inject as service } from '@ember/service';
import moment from 'moment';
import 'moment-timezone';

// NOTE: Constants to be passed into 'appendDiv' and 'addClass'
import markupBuilders from 'calendar/utils/markupBuilders';
import { setMarkupTags, markupOptionsByTag } from 'calendar/utils/markupTags';
import { POST_MARKUP_CLASSES } from 'later/utils/calendar/constants';

import type { EventApi } from '@fullcalendar/common';
import type { MarkupOptions, MarkupOptionsByType } from 'calendar/utils/markupTags';
import type IntlService from 'ember-intl/services/intl';
import type GramModel from 'later/models/gram';
import type SocialProfileModel from 'later/models/social-profile';
import type ErrorsService from 'later/services/errors';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type UserConfigService from 'later/services/user-config';

export enum MarkupInsertionType {
  Append = 'append',
  Prepend = 'prepend'
}

export default class MarkupService extends Service {
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare userConfig: UserConfigService;

  get multipleProfilesSelected(): boolean {
    return this.selectedSocialProfiles.hasMultipleSelected;
  }

  get singleSocialProfile(): SocialProfileModel | undefined {
    return this.selectedSocialProfiles.firstProfile;
  }

  insertMarkup(insertionType: MarkupInsertionType, el: HTMLElement, markup: string): HTMLElement {
    const markupContainer = document.createElement('div');
    markupContainer.innerHTML = markup;
    el[insertionType](markupContainer);
    return markupContainer;
  }

  // Note: Necessary due to fullcalendar limitations: https://github.com/fullcalendar/fullcalendar/issues/6094
  updateCalendarSettingsButton(): void {
    if (this.multipleProfilesSelected) {
      return;
    }

    const settingsButton = document.querySelector('.fc-calendarSettings-button');

    if (this.singleSocialProfile?.isInstagram) {
      settingsButton?.classList.add('at--holisticLibrary__calendarSettingsBtn--ig');
    } else {
      settingsButton?.classList.add('at--holisticLibrary__calendarSettingsBtn--nonIg');
    }
  }

  // Note: Add common classes and markup for posts
  updateCommonPostMarkup(postElements: Record<string, HTMLElement>, event: EventApi, isSelectMode?: boolean): void {
    const { extendedProps } = event;
    const { postTimeContainer, postContentContainer, postIconContainer, eventContentWrapper } = postElements;
    const { post } = extendedProps;
    const { isText } = (post || {}) as GramModel;

    this.#getAndApplyUniversalPostMarkup(postTimeContainer, postContentContainer, event);
    this.#addClass(eventContentWrapper, POST_MARKUP_CLASSES.FC_POST);
    this.#applyIconStyling(postIconContainer, isText);

    const tags = setMarkupTags(event, isSelectMode);

    tags.forEach((tag: string) => {
      // Note: Traverse 'markupOptionsByTag' object allowing us to use dynamic nested key strings such as 'default.draft'
      const markupOptions = tag.split('.').reduce((acc: MarkupOptionsByType | MarkupOptions, item) => {
        // @ts-expect-error we are relying on tags being formed with the proper keys to access this data structure
        // turning off type checking as we need to account for the acc being either MarkupOptionsByType or MarkupOptions
        // TS complains that item can't index MarkupOptions but if the tag is correct, once we hit a MarkupOptions the iteration should be over.
        return acc[item];
      }, markupOptionsByTag(this.intl)) as MarkupOptions;

      if (!markupOptions) {
        this.errors.log('updateCommonPostMarkup: Invalid markup tag', { tag });
        return;
      }

      // Note: If multiple option sets provided, apply each of them
      if (Array.isArray(markupOptions)) {
        markupOptions.forEach((optionSet) =>
          this.#getAndApplyPostStatusAndMethodMarkup(postIconContainer, eventContentWrapper, optionSet)
        );
      } else {
        this.#getAndApplyPostStatusAndMethodMarkup(postIconContainer, eventContentWrapper, markupOptions);
      }
    });
  }

  // Note: Necessary due to fullcalendar limitations: https://github.com/fullcalendar/fullcalendar/issues/6094
  updateStoriesButton(): void {
    const storiesButton = document.querySelector('.fc-storiesButton-button');
    storiesButton?.classList.add(POST_MARKUP_CLASSES.FC_BUTTON_ACTIVE);
  }

  // Note: Add class to an element
  #addClass(element: HTMLElement, classString: string): void {
    element.classList.add(classString);
  }

  // Note: Add narrow styling to text-only event if postIconContainer has overlapping conditions
  #applyIconStyling(postIconContainer: HTMLElement, isText: boolean): void {
    const { zIndex, marginRight } = postIconContainer.style;
    if ((Number(zIndex) > 1 || marginRight === '20px') && isText) {
      this.#addClass(postIconContainer, POST_MARKUP_CLASSES.IS_NARROW);
    }
  }

  // Note: Apply markup and styling based on the applied posting method and statuses
  #getAndApplyPostStatusAndMethodMarkup(
    postIconContainer: HTMLElement,
    eventContentWrapper: HTMLElement,
    markupOptions = {} as MarkupOptions
  ): HTMLElement {
    const contentWrappers = markupOptions.contentWrapperClasses || [];
    // Note: Only apply classes if they exist and aren't already applied
    contentWrappers.forEach((wrapperClass: string) => {
      if (!eventContentWrapper.classList.contains(wrapperClass)) {
        this.#addClass(eventContentWrapper, wrapperClass);
      }
    });

    const { className, icon, hasOverlay, text } = markupOptions;
    const markup = markupBuilders.postStatusAndMethodContent({
      className,
      icon,
      overlay: hasOverlay ? "<span class='fc-overlay'></span>" : '',
      text
    });
    return this.insertMarkup(MarkupInsertionType.Append, postIconContainer, markup);
  }

  #getAndApplyUniversalPostMarkup(
    postTimeContainer: HTMLElement,
    postContentContainer: HTMLElement,
    event: EventApi
  ): void {
    const { extendedProps, start: eventTime } = event;
    const { profileColorClass, calendarOrProcessingUrl, post } = extendedProps;
    const { caption } = post;

    if (!this.userConfig.currentTimeZone?.identifier) {
      throw new Error('No timezone identifier specified via `userConfig.currentTimeZone` - ensure config is setup');
    }

    const eventTimeMoment = eventTime ? moment(eventTime) : moment();
    const formattedEventTime = eventTimeMoment.tz(this.userConfig.currentTimeZone.identifier).format('h:mm');

    const imageMarkup = markupBuilders.imagePost(calendarOrProcessingUrl);
    const textMarkup = markupBuilders.textPost(caption);
    const imageOrTextMarkup = event.extendedProps.post?.isText ? textMarkup : imageMarkup;
    const emptyDraftMarkup = markupBuilders.imagePost('/assets/images/img--postThumbnail--placeholder--calendar.png');
    const finalContentMarkup = event.extendedProps.post?.isEmptyDraft ? emptyDraftMarkup : imageOrTextMarkup;

    postTimeContainer.innerHTML = markupBuilders.eventTime(formattedEventTime);
    postContentContainer.innerHTML = markupBuilders.universalPostContent(
      event.id,
      profileColorClass,
      finalContentMarkup
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    'calendar/markup': MarkupService;
  }
}
