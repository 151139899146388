import type { UntypedService } from 'shared/types';

export async function keycloakRegister(keycloak: UntypedService): Promise<void> {
  try {
    if (!keycloak.isInitialized) {
      await keycloak.initialize();
    }
    if (!keycloak.session.isAuthenticated) {
      await keycloak.register();
    }
  } catch (error) {
    keycloak.router.transitionTo('/');
  }
}
