import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import LogRocket from 'logrocket';

import { isProduction } from 'later/utils/is-env';

interface IUserTraits {
  [propName: string]: string | number | boolean;
}

export type TrackEventProperties = {
  revenue?: number;
  [key: string]: string | number | boolean | string[] | number[] | boolean[] | undefined | null;
};

export default class LogRocketService extends Service {
  @tracked userId: string | undefined;

  /**
   * Creates a new instance of the LogRocket service.
   * Automatically initializes LogRocket during service instantiation.
   */
  constructor(...args: Record<string, unknown>[]) {
    super(...args);
    this.setupLogRocket();
  }

  /**
   * Initializes LogRocket with our application ID.
   * Only initializes in production environments.
   */
  setupLogRocket(): void {
    if (!isProduction()) {
      return;
    }
    LogRocket.init('nplwar/applatercom');
  }

  /**
   * Identifies the current user within LogRocket.
   * Associates user traits with their identity for better session context.
   *
   * This method is typically called from the Segment service once user information is available.
   *
   * @param userId - The unique identifier for the current user
   * @param userTraits - Object containing user properties to associate with sessions
   */
  identify(userId: string, userTraits: IUserTraits): void {
    if (!isProduction()) {
      return;
    }

    LogRocket.identify(userId, userTraits);
    LogRocket.track('identify', userTraits);
  }

  /**
   * Tracks a custom event in LogRocket.
   * Only sends events in production environments.
   *
   * This method is typically called from the Segment service as part of unified analytics.
   *
   * @param eventName - Name of the event to track
   * @param payload - Properties to associate with the event
   */
  track(eventName: string, payload: TrackEventProperties): void {
    if (!isProduction()) {
      return;
    }

    LogRocket.track(eventName, payload);
  }
}

// Add TypeScript type declaration for the service
declare module '@ember/service' {
  interface Registry {
    'log-rocket': LogRocketService;
  }
}
