import { service } from '@ember/service';
import Model, { attr, belongsTo } from '@ember-data/model';
import moment from 'moment';

import { shiftTimeToEndOfLocalDay } from 'shared/utils/analytics/timezone-helpers';

import type SocialProfileModel from 'later/models/social-profile';
import type UserConfigService from 'later/services/user-config';
import type { Moment } from 'moment';

const MINUTES_BEFORE_DATA_LOAD = 3;

export default class BrandProfileModel extends Model {
  @service('user-config') declare userConfig: UserConfigService;

  @belongsTo('socialProfile') declare socialProfile: SocialProfileModel;
  @attr('string') declare profileType: string;
  @attr('string') declare nickname: string;
  @attr('string') declare avatarUrl: string;
  @attr('string') declare trackingStartedAt: string;

  get displayName(): string {
    return '@' + this.nickname;
  }

  get timezone(): string {
    return this.userConfig.currentTimeZone?.identifier ?? 'UTC';
  }

  get startDate(): Moment {
    const twoWeeksAgo = moment().subtract(2, 'weeks');

    if (this.createdAtMoment.unix() > twoWeeksAgo.unix()) {
      return this.createdAtMoment;
    }
    return twoWeeksAgo;
  }

  get endDate(): Moment {
    return moment();
  }

  get createdAtMoment(): Moment {
    if (this.trackingStartedAt === null) {
      return moment();
    }

    return moment(this.trackingStartedAt);
  }

  get predictedEndDate(): Moment {
    return moment(shiftTimeToEndOfLocalDay(this.createdAtMoment.clone().add(3, 'month').unix(), this.timezone));
  }

  get isBackfilling(): boolean {
    const backfillEndTime = this.createdAtMoment.clone().add(MINUTES_BEFORE_DATA_LOAD, 'minutes');
    return moment().unix() < backfillEndTime.unix();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    brandProfile: BrandProfileModel;
  }
}
