import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import getPage from 'later/utils/get-page-from-window';

import type RouterService from '@ember/routing/router-service';
import type AdaService from 'later/services/ada';
import type AuthService from 'later/services/auth';
import type IntegrationsQueueService from 'later/services/integrations-queue';
import type ZendeskService from 'later/services/zendesk';

export default class ClusterController extends Controller {
  @service declare ada: AdaService;
  @service declare auth: AuthService;
  @service declare integrationsQueue: IntegrationsQueueService;
  @service declare router: RouterService;
  @service declare zendesk: ZendeskService;

  get hideAppNavigation(): boolean {
    return this.isOnUnsubscribeRoute || this.isOnCampaignApplicationRoute;
  }

  get isOnCampaignApplicationRoute(): boolean {
    return this.router.currentRouteName.includes('partnerships.campaign-application');
  }

  get isOnUnsubscribeRoute(): boolean {
    return this.router.currentRouteName.endsWith('unsubscribe');
  }

  @action
  openChatbot(location: string): void {
    const { rolloutZendeskChatbot } = this.auth.currentAccount;
    const chatbotName = rolloutZendeskChatbot ? this.zendesk.name : this.ada.name;
    this.integrationsQueue.addToQueue(chatbotName, true);

    if (rolloutZendeskChatbot) {
      this.zendesk.open(location, getPage());
    } else {
      this.ada.open(location, getPage());
    }
  }

  @action
  logout(): void {
    this.auth.logout.perform();
  }
}
