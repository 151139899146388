import { debug } from '@ember/debug';
import EmberRouter from '@ember/routing/router';
import { inject as service } from '@ember/service';

import config from 'later/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;

  @service adaCampaigns;
  @service auth;
  @service performanceTracking;

  constructor(owner, ...args) {
    super(owner, ...args);

    this.on('routeDidChange', (transition) => {
      try {
        if (transition.from?.name) {
          this.performanceTracking.saveStoredMeasurements(transition.from.name);
        }

        if (!this.auth.currentAccount?.rolloutZendeskChatbot) {
          this.adaCampaigns.initAll.perform();
        }
      } catch (error) {
        debug(error);
      }
    });
  }
}

// Not a typical "map" array method
// eslint-disable-next-line array-callback-return
Router.map(function () {
  this.route('change_plan', { path: '/change_plan/:plan' });
  this.route('mobile');

  this.route('cluster', { path: '/:profile_path' }, function () {
    this.route('schedule', function () {
      this.mount('calendar');
      this.route('list', function () {
        this.route('media', { path: '/media/:media_item_id' });
        this.mount('post', { path: '/' });
      });
      // Note: The 'drafts' route is in the process of being deprecated
      this.route('drafts', function () {
        this.route('media', { path: '/media/:media_item_id' });
        this.mount('post', { path: '/' });
      });
      this.route('stories', function () {
        this.mount('editor');
      });
      this.mount('preview-grid', { path: '/preview' });
    });

    this.mount('analytics');
    this.mount('collect');
    this.mount('conversations');
    this.mount('custom-analytics');
    this.mount('labs');
    this.mount('linkinbio');
    this.mount('media');
    this.route('planner', function () {
      this.route('content-pillars');
    });
    this.mount('post');
    this.mount('social-listening');
    this.mount('influencers', { as: 'partnerships' });
    this.mount('media-kit');
    this.mount('social-inbox', { as: 'messages' });
  });

  this.route('extension', function () {
    this.route('add');
  });

  this.route('featurelink', function () {
    this.route('analytics');
    this.route('conversations');
    this.route('feature', { path: '/:feature' });
    this.route('linkinbio');
    this.route('partnerships', { path: '/partnerships/:feature' });
    this.route('campaigns', { path: `manage-campaigns` });
    this.route('campaigns', { path: `/campaigns/:campaign_slug/:feature/:feature_slug` });
    this.route('campaigns', { path: `/campaigns/:campaign_slug/:feature` });
    this.route('campaigns', { path: `/campaigns/:campaign_slug` });
    this.route('surveys', { path: `/surveys/:survey_slug` });
  });

  this.route('partnership_terms');
  this.route('nogroup');
  this.route('not-found', { path: '/*not-found' });
  this.route('referrals');

  this.mount('plans');
  this.mount('settings', { as: 'account' });
  this.mount('styleguide', { path: '/style-guide' });
  this.mount('auth', { as: 'user' });
  this.mount('auth', { as: 'campaigns' });
});
