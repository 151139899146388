import { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

import { isInRange } from 'later/utils/number-helpers';

// Instagram: https://developers.facebook.com/docs/instagram-api/reference/user/media#create-video-container
const INSTAGRAM_MIN_FRAMERATE = 23;
const INSTAGRAM_MAX_FRAMERATE = 60;

// Twitter: https://developer.twitter.com/en/docs/media/upload-media/uploading-media/media-best-practices.html
const TWITTER_MAX_FRAMERATE = 60;

//Linkedin: https://www.linkedin.com/help/linkedin/answer/106595/video-sharing-troubleshooting-guide?lang=en
const LINKEDIN_MIN_FRAMERATE = 10;
const LINKEDIN_MAX_FRAMERATE = 60;

//TikTok: https://bytedance.feishu.cn/docs/doccnq3SeHrZkncd0AdxL69MCEe#DMh0RO
const TIKTOK_MIN_FRAMERATE = 23;
const TIKTOK_MAX_FRAMERATE = 60;

//Facebook Reel: https://developers.facebook.com/docs/video-api/guides/reels-publishing/
const FACEBOOK_REEL_MIN_FRAMERATE = 23;

//Threads: https://developers.facebook.com/docs/threads/overview#video-specifications
const THREADS_MIN_FRAMERATE = 23;
const THREADS_MAX_FRAMERATE = 60;

// Facebook: no limitations https://developers.facebook.com/docs/graph-api/video-uploads
// Pinterest: no published specs
// Snapchat: no limitations: https://developers.snap.com/api/marketing-api/Public-Profile-API/ProfileAssetManagement
@classic
class ValidVideoFramerate extends BaseValidator {
  @service intl;

  validate(value, _, model) {
    const framerate = model.get('mediaItem').get('videoFrameRate');
    const isVideo = model.get('mediaItem').get('isVideo');

    if (isVideo && framerate) {
      if (model.isInstagram) {
        if (model.isAutoPublish && !isInRange(framerate, INSTAGRAM_MIN_FRAMERATE, INSTAGRAM_MAX_FRAMERATE)) {
          if (framerate < INSTAGRAM_MIN_FRAMERATE) {
            return this.createErrorMessage('invalidVideoFrameRateTooLow', null, {
              min: INSTAGRAM_MIN_FRAMERATE,
              max: INSTAGRAM_MAX_FRAMERATE,
              platform: this.intl.t('shared_words.instagram')
            });
          } else if (framerate > INSTAGRAM_MAX_FRAMERATE) {
            return this.createErrorMessage('invalidVideoFrameRateTooHigh', null, {
              min: INSTAGRAM_MIN_FRAMERATE,
              max: INSTAGRAM_MAX_FRAMERATE,
              platform: this.intl.t('shared_words.instagram')
            });
          }
        }
      } else if (model.isTwitter) {
        if (!isInRange(framerate, null, TWITTER_MAX_FRAMERATE)) {
          if (framerate > TWITTER_MAX_FRAMERATE) {
            return this.createErrorMessage('invalidVideoFrameRateTooHigh', null, {
              min: 0,
              max: TWITTER_MAX_FRAMERATE,
              platform: this.intl.t('shared_words.twitter')
            });
          }
        }
      } else if (model.isFacebookReel) {
        if (!isInRange(framerate, FACEBOOK_REEL_MIN_FRAMERATE, null)) {
          if (framerate < FACEBOOK_REEL_MIN_FRAMERATE) {
            return this.createErrorMessage('invalidVideoFrameRateTooLow', null, {
              min: FACEBOOK_REEL_MIN_FRAMERATE,
              max: 0,
              platform: this.intl.t('shared_phrases.facebook_reels')
            });
          }
        }
      } else if (model.isLinkedin) {
        if (!isInRange(framerate, LINKEDIN_MIN_FRAMERATE, LINKEDIN_MAX_FRAMERATE)) {
          if (framerate < LINKEDIN_MIN_FRAMERATE) {
            return this.createErrorMessage('invalidVideoFrameRateTooLow', null, {
              min: LINKEDIN_MIN_FRAMERATE,
              max: LINKEDIN_MAX_FRAMERATE,
              platform: this.intl.t('shared_words.linkedin')
            });
          } else if (framerate > LINKEDIN_MAX_FRAMERATE) {
            return this.createErrorMessage('invalidVideoFrameRateTooHigh', null, {
              min: LINKEDIN_MIN_FRAMERATE,
              max: LINKEDIN_MAX_FRAMERATE,
              platform: this.intl.t('shared_words.linkedin')
            });
          }
        }
      } else if (model.isTiktok) {
        if (model.isAutoPublish && !isInRange(framerate, TIKTOK_MIN_FRAMERATE, TIKTOK_MAX_FRAMERATE)) {
          if (framerate < TIKTOK_MIN_FRAMERATE) {
            return this.createErrorMessage('invalidVideoFrameRateTooLow', null, {
              min: TIKTOK_MIN_FRAMERATE,
              max: TIKTOK_MAX_FRAMERATE,
              platform: this.intl.t('shared_words.tiktok')
            });
          } else if (framerate > TIKTOK_MAX_FRAMERATE) {
            return this.createErrorMessage('invalidVideoFrameRateTooHigh', null, {
              min: TIKTOK_MIN_FRAMERATE,
              max: TIKTOK_MAX_FRAMERATE,
              platform: this.intl.t('shared_words.tiktok')
            });
          }
        }
      } else if (model.isThreads) {
        if (!isInRange(framerate, THREADS_MIN_FRAMERATE, THREADS_MAX_FRAMERATE)) {
          if (framerate < THREADS_MIN_FRAMERATE) {
            return this.createErrorMessage('invalidVideoFrameRateTooLow', null, {
              min: THREADS_MIN_FRAMERATE,
              max: THREADS_MAX_FRAMERATE,
              platform: this.intl.t('shared_words.threads')
            });
          } else if (framerate > THREADS_MAX_FRAMERATE) {
            return this.createErrorMessage('invalidVideoFrameRateTooHigh', null, {
              min: THREADS_MIN_FRAMERATE,
              max: THREADS_MAX_FRAMERATE,
              platform: this.intl.t('shared_words.threads')
            });
          }
        }
      }
    }
    return true;
  }
}

export default ValidVideoFramerate;
