import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { isNone } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';

const SNAPCHAT_PUBLIC_STORY_DIMENSIONS = {
  min: {
    width: 1080,
    height: 1920
  }
};

/**
 *  Validates the dimensions (resolution) of a post media item
 *
 *  @class ImageDimensions
 *  @module Validators
 *  @extends Base
 */
export default BaseValidator.extend({
  intl: service(),
  schedulePost: service('schedule/post'),

  isSnapchatStory: readOnly('model.gram.isSnapchatStory'),
  isImage: readOnly('model.isImage'),
  isNotificationPost: readOnly('model.isNotificationPost'),
  hasPublishingMethod: readOnly('model.gram.socialProfile.hasPublishingMethod'),
  profileType: readOnly('model.gram.socialProfile.profileType'),
  width: readOnly('model.width'),
  height: readOnly('model.height'),
  mediaItemWidth: readOnly('model.mediaItem.width'),
  mediaItemHeight: readOnly('model.mediaItem.height'),

  validate() {
    if (this.isNotificationPost || !this.hasPublishingMethod || !this.isImage || !this.isSnapchatStory) {
      return true;
    }

    const platform = this.profileType ? capitalize(this.profileType) : null;

    // Get dimensions from the post media item or fall back to media item
    let { height, width } = this;

    if (isNone(width) || isNone(height)) {
      width = this.mediaItemWidth;
      height = this.mediaItemHeight;
    }

    // Skip validation if dimensions are not available
    if (isNone(width) || isNone(height)) {
      return true;
    }

    const { min } = SNAPCHAT_PUBLIC_STORY_DIMENSIONS;

    // Check if image is too small
    if (width < min.width || height < min.height) {
      return this.createErrorMessage('imageDimensionsTooSmall', null, {
        minWidth: min.width,
        minHeight: min.height,
        platform
      });
    }

    return true;
  }
});
