import { readOnly } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import timeGridPlugin from '@fullcalendar/timegrid';
import { task } from 'ember-concurrency';
import 'moment-timezone';
import { tracked } from 'tracked-built-ins';

import patchedInteractionPlugin from 'later/utils/calendar/fullcal-rightclick';
import { CALENDAR_TYPES, CALENDAR_VIEW_TYPES } from 'later/utils/constants';

import type { Calendar, CalendarOptions } from '@fullcalendar/core';
import type SocialProfileModel from 'later/models/social-profile';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type ProgressBarService from 'later/services/progress-bar';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type UserConfigService from 'later/services/user-config';
import type { CalendarViews } from 'later/utils/constants';
import type { Maybe } from 'shared/types';

interface HeaderToolbar {
  left: string;
  right: string;
}

const { DEFAULT, SELECT, STORIES } = CALENDAR_TYPES;
const { WEEK } = CALENDAR_VIEW_TYPES;

export default class ConfigService extends Service {
  @service declare auth: AuthService;
  @service declare errors: ErrorsService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare userConfig: UserConfigService;
  @service declare progressBar: ProgressBarService;

  @tracked startTime: Maybe<Date> = null;
  @tracked endTime: Maybe<Date> = null;
  @tracked fcView: CalendarViews = WEEK;
  // Note: This constant can eventually be replaced with a TypeScript enum
  @tracked calendarType: typeof STORIES | typeof DEFAULT | typeof SELECT = DEFAULT;

  fullCalendar: Maybe<Calendar> = null;

  @readOnly('selectedSocialProfiles.hasMultipleSelected') declare multipleProfilesSelected: boolean;
  @readOnly('selectedSocialProfiles.firstProfile') declare singleSocialProfile: SocialProfileModel;

  get timeZoneIdentifier(): string {
    return this.userConfig.currentTimeZone?.identifier || '';
  }

  get calendarView(): CalendarViews {
    return this.fcView;
  }

  set calendarView(value: CalendarViews) {
    try {
      this.fcView = value;
    } catch {
      this.errors.log('set calendarView Error', { attemptedValue: value });
    }
  }

  get commonConfig(): CalendarOptions {
    return {
      allDaySlot: false,
      headerToolbar: this.headerToolbar,
      nowIndicator: true,
      // Note: Using patchedInteractionPlugin instead of interactionPlugin to support right-clicking
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, patchedInteractionPlugin, momentTimezonePlugin],
      progressiveEventRendering: true,
      scrollTime: '07:00:00',
      snapDuration: '00:05:00',
      timeZone: this.timeZoneIdentifier
    };
  }

  get headerToolbar(): HeaderToolbar {
    // Note: Join is necessary here due to an issue with fullcalendar (https://stackoverflow.com/questions/64500933/fullcalendar-headertoolbar-button-displays-as-two-buttons)
    return {
      left: this.leftHeaders.join(' '),
      right: this.rightHeaders.join(' ')
    };
  }

  get leftHeaders(): string[] {
    const defaultCalendar = this.calendarType === DEFAULT;
    const defaultLeft = ['today,prev,next title'];
    const storiesLeft = ['today,prev,next title'];
    return defaultCalendar ? defaultLeft : storiesLeft;
  }

  get rightHeaders(): string[] {
    const defaultCalendar = this.calendarType === DEFAULT;
    const selectCalendar = this.calendarType === SELECT;
    const displayAdditionalButtons = this.singleSocialProfile?.isInstagram && !this.multipleProfilesSelected;
    const socialProfileButtons = displayAdditionalButtons
      ? 'timezoneButton storiesButton previewButton,'
      : 'timezoneButton ';
    const sendPosts = this.auth.currentAccount.canExternalPostReview ? ' sendPosts' : '';
    const defaultRight = [`${socialProfileButtons}timeGridWeek,dayGridMonth,listButton calendarSettings${sendPosts}`];
    const storiesRight = [`timezoneButton storiesButton previewButton,weekButton,monthButton,listButton`];
    const selectRight = [`selectAll createLink cancel`];
    if (selectCalendar) {
      return selectRight;
    } else if (defaultCalendar) {
      return defaultRight;
    }
    return storiesRight;
  }

  isValidCalendarView(viewToValidate: string): boolean {
    return (Object.values(CALENDAR_VIEW_TYPES) as string[]).includes(viewToValidate);
  }

  targetDateObject(targetDate: string): Date {
    const targetDateTimestamp = Number(targetDate) || new Date().getTime();
    return new Date(targetDateTimestamp);
  }

  handleWithProgressBar = task(async (runTasks: () => unknown) => {
    this.progressBar.start('.fc-view-harness');
    const element = document.querySelector('#nprogress');
    if (element) {
      element.classList.add('qa--calendar_progress_bar');
    }
    await runTasks();
    // handleWithProgressBar might be cancelled so we need to force the progress bar to finish on the last task
    this.progressBar.done('.fc-view-harness', true);
  });
}

declare module '@ember/service' {
  interface Registry {
    'calendar/config': ConfigService;
  }
}
