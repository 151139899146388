import classic from 'ember-classic-decorator';

import ApplicationSerializer from './application';

@classic
export default class Account extends ApplicationSerializer {
  normalizeResponse(store, primaryModelClass, payload, ...args) {
    delete payload.clusters;

    return super.normalizeResponse(store, primaryModelClass, payload, ...args);
  }
}
