import ApplicationSerializer from 'later/serializers/application';

import type Store from '@ember-data/store';
import type Snapshot from '@ember-data/store/snapshot';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports -- we need ModelSchema here for the normalizeResponse and it is not available in @ember-data
import type { ModelSchema } from 'ember-data';
import type ModelRegistry from 'ember-data/types/registries/model';

export default class CompetitorGroupSerializer extends ApplicationSerializer {
  normalizeResponse(
    store: Store,
    primaryModelClass: ModelSchema<keyof ModelRegistry>,
    payload: object,
    id: string | number,
    requestType: string
  ): object {
    // Note: maps BE response to ember-data compatible response
    return super.normalizeResponse(store, primaryModelClass, { 'competitor-group': payload }, id, requestType);
  }

  serializeIntoHash(
    hash: Record<string, unknown>,
    _typeClass: ModelSchema<keyof ModelRegistry>,
    snapshot: Snapshot<keyof ModelRegistry>,
    options: object
  ): void {
    // Note: flattens the serialized request payload to prevent ember-data from nesting the payload under the model name
    const serialized = this.serialize(snapshot, options);

    Object.assign(hash, serialized);
  }
}
