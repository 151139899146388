import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Keycloak from 'keycloak-js';

const TOKEN_REFRESH_INTERVAL = 300; // 5 minutes in seconds

export default class KeycloakAuthService extends Service {
  @service laterConfig;
  @service auth;
  @service errors;
  @service router;
  // eslint-disable-next-line ember/no-unused-services
  @service session; // used by utils/keycloak-register

  @tracked isInitialized = false;
  @tracked client;

  #tokenRefreshInterval = TOKEN_REFRESH_INTERVAL;

  constructor(...args) {
    super(...args);
    this.setupClient();
  }

  async setupClient() {
    try {
      if (!this.client) {
        await this.laterConfig.setup();
        this.client = new Keycloak(this.keycloakConfig);
      }
    } catch (error) {
      this.errors.log('Failed to setup Keycloak client', error);
    }
  }

  /**
   * Initialize Keycloak authentication
   */
  async initialize() {
    await this.setupClient();
    if (!this.client) {
      this.errors.log('Keycloak client is not setup');
    }

    try {
      const initOptions = {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
        enableLogging: true,
        pkceMethod: false,
        responseMode: 'query'
      };

      await this.client.init(initOptions);
      this.isInitialized = true;
    } catch (error) {
      this.isInitialized = false;
      this.errors.log('Failed to initialize Keycloak authentication');
    }
  }

  /**
   * Log in the user
   */
  async login() {
    this.ensureInitialized();

    if (!this.isAuthenticated) {
      try {
        await this.client.login();
      } catch (error) {
        this.errors.log('Keycloak login failed', error);
      }
    } else {
      console.info('User is already authenticated');
      this.router.transitionTo('index');
    }
  }

  #getFeatureLinkFromPath() {
    // Match pattern /featurelink/{feature}
    const pathRegex = /^\/featurelink\/([a-z]+)\/?$/;
    const match = window.location.pathname.match(pathRegex);

    if (!match) {
      return undefined;
    }

    return match[1];
  }

  #getCampaignIdFromPath() {
    // Match pattern /campaigns/{numbers}/signup
    const pathRegex = /^\/campaigns\/(\d+)\/signup\/?$/;
    const match = window.location.pathname.match(pathRegex);

    if (!match) {
      return undefined;
    }

    return match[1];
  }

  #getPlanFromPath() {
    // Match pattern /plans/{plan}/signup
    const pathRegex = /^\/plans\/([a-z]+)\/?$/;
    const match = window.location.pathname.match(pathRegex);

    if (!match) {
      return undefined;
    }

    return match[1];
  }

  #buildRedirectUrlParams(baseSearchParams) {
    const searchParams = new URLSearchParams(baseSearchParams);

    // Add campaign ID if exists
    const campaignId = this.#getCampaignIdFromPath();
    if (campaignId) {
      searchParams.set('campaign_id', campaignId);
    }

    // Add plan if it exists
    const plan = this.#getPlanFromPath();
    if (plan) {
      searchParams.set('plan', plan);
    }

    // Add feature link if exists
    const featureLink = this.#getFeatureLinkFromPath();
    if (featureLink) {
      searchParams.set('feature_link', featureLink);
    }

    // BE + Keycloak token exchange expects redirectUrl query params to be sorted
    searchParams.sort();
    return searchParams;
  }

  /**
   * Register the user
   */
  async register() {
    this.ensureInitialized();
    if (this.isAuthenticated) {
      this.client.logout();
    }

    if (!this.isAuthenticated) {
      try {
        const redirectUrl = new URL('/api/keycloak/signup', window.location.origin);
        const searchParams = this.#buildRedirectUrlParams(window.location.search);
        for (const [key, value] of searchParams.entries()) {
          redirectUrl.searchParams.set(key, value);
        }

        await this.client.register({
          redirectUri: redirectUrl.toString()
        });
      } catch (error) {
        this.errors.log('Keycloak registration failed:', error);
      }
    } else {
      console.info('User is already authenticated');
    }
  }

  /**
   * Refresh the authentication token
   * @returns {Promise<string>} The refreshed token
   */
  async refreshToken() {
    this.ensureInitialized();

    if (!this.isAuthenticated) {
      return;
    }

    try {
      const isRefreshed = await this.client.updateToken(this.#tokenRefreshInterval);

      if (!isRefreshed) {
        throw new Error('Token refresh failed');
      }

      return this.token;
    } catch (error) {
      this.errors.log('Keycloak token refresh failed:', error);
    }
  }

  /**
   * Ensure Keycloak is initialized
   */
  ensureInitialized() {
    if (!this.isInitialized) {
      this.errors.log('Keycloak is not initialized');
    }
  }

  get keycloakConfig() {
    return {
      url: 'https://auth.later.com/auth',
      realm: this.auth.keycloakRealm,
      clientId: 'later'
    };
  }

  /**
   * Token getter
   * @returns {string|undefined}
   */
  get token() {
    return this.client?.token ?? undefined;
  }

  /**
   * Check if the user is authenticated
   * @returns {boolean}
   */
  get isAuthenticated() {
    return Boolean(this.client?.authenticated);
  }

  willDestroy() {
    super.willDestroy();
    this.client = undefined;
    this.isInitialized = false;
  }
}
