import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Model, { attr, belongsTo } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import moment from 'moment';

import type GroupModel from 'later/models/group';
import type UserConfigService from 'later/services/user-config';
import type { Moment } from 'moment';

const Validations = buildValidations({
  name: [
    validator('presence', {
      presence: true,
      message: 'social-listening.competitors.form.errors.name.empty'
    }),
    validator('length', {
      max: 30,
      message: 'social-listening.competitors.form.errors.name.length'
    })
  ],
  socialProfileIds: [
    validator('length', {
      min: 1,
      message: 'social-listening.competitors.form.errors.social_profile_ids.empty'
    })
  ]
});

export default class CompetitorGroupModel extends Model.extend(Validations) {
  @service('user-config') declare userConfig: UserConfigService;

  @belongsTo('group') declare group: GroupModel;
  @attr('string') declare createdAt: string;
  @attr('string') declare name: string;
  // @ts-expect-error Fixed in @types/ember-data v4
  @attr('array') declare competitors: string[];
  // @ts-expect-error Fixed in @types/ember-data v4
  @attr('array') declare socialProfileIds: number[];

  get timezone(): string {
    return this.userConfig.currentTimeZone?.identifier ?? 'UTC';
  }

  get createdAtMoment(): Moment {
    return moment.unix(Number(this.createdAt));
  }

  get hasContent(): boolean {
    return isPresent(this.name) || this.competitors?.length > 0 || this.socialProfileIds?.length > 0;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    competitorGroup: CompetitorGroupModel;
  }
}
