import { readOnly } from '@ember/object/computed';
import { validator, buildValidations } from 'ember-cp-validations';

const isDraft = readOnly('model.gram.isDraft');

const Validations = buildValidations({
  altText: [
    validator('alt-text-length', {
      dependentKeys: ['model.gram.{isDraft,socialProfile}', 'model.ordering'],
      isWarning: isDraft
    })
  ],
  media: [
    validator('aspect-ratio', {
      lazy: false,
      dependentKeys: [
        'model.{aspectRatio,isInstagram,isLinkedin,isPinterest,isTwitter,isFacebook,isVideo,isNotificationPost,isInstagramReel,isCustomCover,isYoutubeShort,isSnapchat}',
        'model.gram.{isDraft,socialProfile,isInstagramStory,isSpotlight}',
        'model.mediaItem.aspectRatio'
      ],
      isWarning: isDraft,
      disabled: !readOnly('model.shouldValidateAspectRatio')
    }),
    validator('image-dimensions', {
      lazy: false,
      dependentKeys: [
        'model.{isSnapchat,isImage,width,height}',
        'model.gram.{isSnapchatStory,isDraft,socialProfile,socialProfile.profileType}',
        'model.mediaItem.{width,height}'
      ],
      isWarning: isDraft,
      disabled: !readOnly('model.shouldValidateImageDimensions')
    }),
    validator('image-quality-reduced', {
      isWarning: true,
      dependentKeys: [
        'model.{isAutoPublish,isFacebook,isInstagram,isTwitter}',
        'model.mediaItem.{publishingFileSize,isImage}'
      ]
    }),
    validator('file-size', {
      dependentKeys: [
        'model.{isAutoPublish,isGif,isVideo,isImage}',
        'model.gram.{isDraft,socialProfile}',
        'model.mediaItem.publishingFileSize'
      ],
      isWarning: isDraft
    }),
    validator('video-framerate', {
      lazy: false,
      dependentKeys: [
        'model.{isInstagram,isTwitter,isLinkedin,isAutoPublish,isTiktok}',
        'model.gram.isDraft',
        'model.mediaItem.{isVideo,videoFrameRate}'
      ],
      isWarning: isDraft
    }),
    validator('video-duration', {
      lazy: false,
      dependentKeys: [
        'model.{isAutoPublish,isInstagram,isTiktok,videoDuration}',
        'model.gram.isDraft',
        'model.gram.socialProfile.{id,profileType}'
      ],
      isWarning: isDraft
    }),
    validator('video-dimensions', {
      dependentKeys: [
        'model.{croppedWidth,croppedHeight,isVideo}',
        'model.gram.isDraft',
        'model.gram.socialProfile.profileType',
        'model.mediaItem.{width,height}',
        'model.{isAutoPublish,isLinkedin,isTiktok,isFacebookReel,isSnapchat}'
      ],
      isWarning: isDraft
    }),
    validator('media-not-processing', {
      dependentKeys: ['model.gram.isDraft', 'model.mediaItem.processing'],
      isWarning: isDraft
    }),
    validator('video-quality-reduced', {
      isWarning: true,
      dependentKeys: [
        'model.{width,croppedWidth,isVideo}',
        'model.mediaItem.{width,croppedWidth,isVideo}',
        'model.gram.socialProfile.profileType'
      ]
    })
  ]
});

export default Validations;
