import { isEmpty } from '@ember/utils';

import type SocialProfileModel from 'later/models/social-profile';

/**
 * Returns a translation path for a given social profile type, as well as defined
 * parent for restriction info translations
 *
 * @param socialProfile The social profile the path is for
 * @param string The restriction info parent (change_media or carousel for example)
 */
const buildRestrictionsInfoString = (socialProfile: SocialProfileModel, optionalTranslationBase: string): string => {
  if (isEmpty(socialProfile)) {
    return 'post.change_media.restrictions_info.no_social_state_description';
  }

  let socialProfileType;
  const { isLinkedin, profileType } = socialProfile;
  const translationBase = optionalTranslationBase ? optionalTranslationBase : 'post';

  if (isLinkedin) {
    socialProfileType = 'linkedin.page';
  } else {
    socialProfileType = profileType;
  }

  return `${translationBase}.restrictions_info.${socialProfileType}`;
};

export { buildRestrictionsInfoString };
