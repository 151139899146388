import Service from '@ember/service';
import { task } from 'ember-concurrency';

import config from 'later/config/environment';
import { DEFAULT_PRODUCT_FETCH_LIMIT } from 'later/utils/constants';
import graphCall from 'later/utils/graph-call';

export default class FacebookGraphService extends Service {
  facebookGraphVersion = config.APP.facebookGraphVersion;

  /**
   * List of post fields to be returned from fetch media requests
   */
  postFields = [
    'message',
    'created_time',
    'from',
    'picture',
    'full_picture',
    'permalink_url',
    'type',
    'source',
    'reactions'
  ];

  /**
   * List of comment fields to be returned from fetch comment requests
   */
  commentFields = ['message', 'from', 'created_time', 'parent', 'reactions'];

  /**
   * List of message fields to be returned from fetch message requests
   */
  messageFields = ['message', 'from', 'to', 'attachments', 'created_time'];

  fetchPage = task(async (token: string, pageId: string) => {
    const result = await graphCall(
      `/${encodeURIComponent(pageId)}`,
      { fields: 'name,business' },
      {
        method: 'get',
        token
      }
    );
    return result;
  });

  fetchProduct = task(async (token: string | undefined, productId: string, businessAccountToken: string) => {
    const fields = ['image_url', 'name'];
    const result = await graphCall(
      `/${productId}`,
      { fields: fields.join(',') },
      {
        method: 'get',
        token,
        backupToken: businessAccountToken
      }
    );

    return result;
  });

  fetchProductTagEligibility = task(
    async (instagramUserId: string, token: string | undefined, businessAccountToken: string) => {
      return await graphCall(
        `/${encodeURIComponent(instagramUserId)}`,
        { fields: 'shopping_product_tag_eligibility' },
        {
          method: 'get',
          token,
          backupToken: businessAccountToken
        }
      );
    }
  );

  fetchAvailableCatalogs = task(
    async (instagramUserId: string, token: string | undefined, businessAccountToken: string) => {
      const result = await graphCall(
        `/${encodeURIComponent(instagramUserId)}/available_catalogs`,
        {},
        {
          method: 'get',
          token,
          backupToken: businessAccountToken
        }
      );

      return result;
    }
  );

  fetchCatalogProducts = task(
    async ({
      instagramUserId,
      token,
      params,
      paginationUrl,
      businessAccountToken
    }: {
      instagramUserId: string;
      token?: string;
      params: Record<string, unknown>;
      paginationUrl?: string;
      businessAccountToken: string;
    }) => {
      const catalogProductsUrl = paginationUrl || `/${encodeURIComponent(instagramUserId)}/catalog_product_search`;
      const result = await graphCall(
        catalogProductsUrl,
        { ...params, limit: DEFAULT_PRODUCT_FETCH_LIMIT },
        {
          method: 'get',
          token,
          backupToken: businessAccountToken
        }
      );

      return result;
    }
  );

  fetchProfileById = task(
    async (token: string, profileId: string, isInstagram: boolean = false, params?: Record<string, unknown>) => {
      const profileFields = ['name', isInstagram ? 'profile_pic' : 'picture'];
      try {
        const result = await graphCall(
          `/${encodeURIComponent(profileId)}`,
          { ...params, fields: profileFields.join(',') },
          {
            method: 'get',
            token
          }
        );
        return result;
      } catch (error) {
        // Requested profile_pic field on an IGUser, retry with profile_picture_url instead
        if (isInstagram && error.message.includes('profile_pic')) {
          const igUserProfileFields = ['name', 'profile_picture_url'];
          const result = await graphCall(
            `/${encodeURIComponent(profileId)}`,
            { ...params, fields: igUserProfileFields.join(',') },
            {
              method: 'get',
              token
            }
          );
          return result;
        }
        throw error;
      }
    }
  );

  fetchConversations = task(
    async (token: string, pageId: string, messageLimit?: number, params?: Record<string, unknown>) => {
      const conversationFields = this.buildConversationFields(messageLimit);
      const result = await graphCall(
        `/${encodeURIComponent(pageId)}/conversations`,
        { ...params, fields: conversationFields.join(',') },
        {
          method: 'get',
          token
        }
      );
      if (params && params.platform) {
        result.platform = params.platform;
      }
      return result;
    }
  );

  fetchConversationById = task(
    async (token: string, conversationId: string, messageLimit?: number, params?: Record<string, unknown>) => {
      const conversationFields = this.buildConversationFields(messageLimit);
      const result = await graphCall(
        `/${encodeURIComponent(conversationId)}`,
        { ...params, fields: conversationFields.join(',') },
        {
          method: 'get',
          token
        }
      );
      return result;
    }
  );

  fetchNextMessages = task(async (nextUrl: string, params?: Record<string, unknown>) => {
    return await graphCall(nextUrl, params, { method: 'get' });
  });

  sendMessageToConversation = task(async (token: string, pageId: string, recipientId: string, message: string) => {
    const params = {
      recipient: { id: recipientId },
      message: { text: message },
      // TODO: Add support for tagged messages pending app review
      messaging_type: 'RESPONSE'
    };
    const result = await graphCall(
      `/${encodeURIComponent(pageId)}/messages`,
      { ...params },
      {
        method: 'post',
        token
      }
    );
    return result;
  });

  buildConversationFields(messageLimit?: number): string[] {
    return [
      'participants',
      `messages${messageLimit ? `.limit(${messageLimit})` : ''}{${this.messageFields.join(',')}}`,
      'can_reply',
      'updated_time',
      'message_count',
      'unread_count'
    ];
  }
}

declare module '@ember/service' {
  interface Registry {
    'social/facebook-graph': FacebookGraphService;
  }
}
